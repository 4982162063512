@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "LINESeedJP";
    font-style: normal;
    font-weight: 100;
    src: url("./fonts/LINESeedJP_OTF_Th.otf") format("opentype");
}

@font-face {
    font-family: "LINESeedJP";
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/LINESeedJP_OTF_Rg.otf") format("opentype");
}

@font-face {
    font-family: "LINESeedJP";
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/LINESeedJP_OTF_Bd.otf") format("opentype");
}

@font-face {
    font-family: "LINESeedJP";
    font-style: normal;
    font-weight: 800;
    src: url("./fonts/LINESeedJP_OTF_Eb.otf") format("opentype");
}

body {
    font-family: "LINESeedJP";
}